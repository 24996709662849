<template>
  <BContainer>
    <div :key="j" v-for="(game, j) in games">
      <Game :game-id="game.id"
            :gameType="game.type"
            :favorite="game.favorite"
            :spread="game.spread"
      ></Game>
      <br>
    </div>
  </BContainer>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios'
import { conferenceGroups, picks } from '@/config'

import Game from "@/components/Game";

Vue.use(VueAxios, axios);
export default {
  name: "Scoreboard",
  components: {
    Game,
  },
  props: {
    groupId: String,
  },
  data() {
    return {
      data: {},
      url: "http://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard?groups=",
      events: [],
      games: [],
      picks: picks,
      groups: conferenceGroups,
    }
  },
  mounted: function() {
    this.fetch()
  },
  methods: {
    setGroupId(id) {
      Vue.set(this, "groupId", id);
    },
    fetch() {
      if (this.groupId === 'picks' || typeof this.groupId == "undefined") {
        // default to hard coded picks
        Vue.set(this, "events", this.picks)
      } else if (this.groupId === 'top-25') {
        // sending no group id responds with the top-25 events of the week
        Vue.axios.get(this.url + '').then(res => {
          Vue.set(this, "events", res.data.events);
        })
      } else {
        Vue.axios.get(this.url + this.groupId).then(res => {
          Vue.set(this, "events", res.data.events);
        })
      }
    },
    render() {
      this.games = [];
      for (let i = 0; i < this.events.length; i++) {
        this.games.push({
          index: i,
          id: this.events[i].id,
          type: "cfb",
          favorite: this.events[i].favorite ? this.events[i].favorite : {},
          spread: this.events[i].spread ? this.events[i].spread : {},
          gameDetail: false
        })
      }
    }
  },
  watch: {
    groupId: "fetch",
    events: "render"
  }
}
</script>

<style scoped>

</style>
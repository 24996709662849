export const conferenceGroups = [
    { name: "Picks", id: "picks" },
    { name: "FBS", id: "80" },
    { name: "ACC", id: "1"},
    { name: "American", id: "151"},
    { name: "Big 12", id: "4"},
    { name: "Big Ten", id: "5"},
    { name: "Conf USA", id: "12"},
    { name: "FBS Ind", id: "18"},
    { name: "MAC", id: "15"},
    { name: "Mt. West", id: "17"},
    { name: "Pac 12", id: "9"},
    { name: "SEC", id: "8"},
    { name: "Sun Belt", id: "37"}
]

export const picks = [
    {index: 0, id: "401309937", type: "cfb", favorite: { favorite: "153" }, spread: { spread_text: "(-5.5)"} },
    {index: 1, id: "401282815", type: "cfb", favorite: { favorite: "275" }, spread: { spread_text: "(-5.5)"} },
    {index: 2, id: "401309838", type: "cfb", favorite: { favorite: "2483" }, spread: { spread_text: "(-20.5)"} },
    {index: 3, id: "401281942", type: "cfb", favorite: { favorite: "333" }, spread: { spread_text: "(-19.5)"} },
    {index: 4, id: "401282723", type: "cfb", favorite: { favorite: "2294" }, spread: { spread_text: "(-3.5)"} },
    {index: 5, id: "401287892", type: "cfb", favorite: { favorite: "251" }, spread: { spread_text: "(-8.5)"} },
    {index: 6, id: "401309842", type: "cfb", favorite: { favorite: "30" }, spread: { spread_text: "(-14)"} },
    {index: 7, id: "401281946", type: "cfb", favorite: { favorite: "228" }, spread: { spread_text: "(-3)"} },
    {index: 8, id: "401281948", type: "cfb", favorite: { favorite: "99" }, spread: { spread_text: "(-3)"} },
    {index: 9, id: "401282614", type: "cfb", favorite: { favorite: "87" }, spread: { spread_text: "(-7.5)"} },
]

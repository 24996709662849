<template>
  <BContainer id="pregame-detail">
    <BRow>
      <BCol class=label cols="6">
        <BContainer>
          <BButton variant="none" id="compare-button" class="standard-button mt-0 mb-1 p-2" size="small" pill v-on:click.stop="swap">
            Compare  <BIcon icon="arrow-left-right"></BIcon>
          </BButton>
        </BContainer>
        <BRow :key="i" v-for="(category, i) in categories">
          <BCol>
            {{category}}
          </BCol>
        </BRow>
      </BCol>
      <BCol cols="3">
        <BImg class="team-logo" @click.stop="goToEspn" v-bind:src=this.teamOneStats.team.logo></BImg>
        <BRow>
          <BCol>
            <span class="label">Offense</span>
          </BCol>
        </BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "passing", "totalPointsPerGame", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "passing", "yardsPerGame", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "passing", "passingYardsPerGame", false)}}</BCol></BRow>
        <BRow><BCol>{{ this.getStatisticPerGameFromJson(this.teamOneStats, "passing", "passingAttempts", false) }}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "passing", "passingTouchdowns", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "passing", "interceptions", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "rushing", "rushingYardsPerGame", false)}}</BCol></BRow>
        <BRow><BCol>{{ this.getStatisticPerGameFromJson(this.teamOneStats, "rushing", "rushingAttempts", false) }}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "rushing", "yardsPerRushAttempt", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "rushing", "rushingTouchdowns", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "kicking", "fieldGoalsMade", false)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamOneStats, "scoring", "returnTouchdowns", false)}}</BCol></BRow>

      </BCol>
      <BCol cols="3">
        <BImg class="team-logo" @click.stop="goToEspn" v-bind:src=this.teamTwoStats.team.logo></BImg>
        <BRow>
          <BCol>
            <span class="label">Defense</span>
          </BCol>
        </BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "passing", "totalPointsPerGame", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "passing", "yardsPerGame", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "passing", "passingYardsPerGame", true)}}</BCol></BRow>
        <BRow><BCol>{{this.calculateStatisticPerGame(this.teamTwoStats, "passing", "passingAttempts")}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "passing", "passingTouchdowns", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "passing", "interceptions", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "rushing", "rushingYardsPerGame", true)}}</BCol></BRow>
        <BRow><BCol>{{ this.calculateStatisticPerGame(this.teamTwoStats, "rushing", "rushingAttempts") }}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "rushing", "yardsPerRushAttempt", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "rushing", "rushingTouchdowns", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "scoring", "fieldGoals", true)}}</BCol></BRow>
        <BRow><BCol>{{this.getStatistic(this.teamTwoStats, "scoring", "returnTouchdowns", true)}}</BCol></BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// import {statsMock} from "@/mocks/seasonTeamStatsMock";
// import {statsMockTwo} from "@/mocks/seasonTeamStatsMockTwo";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAxios, axios);
export default {
  name: "Matchup",
  props: {
    gameId: String,
    teamOneId: String,
    teamTwoId: String,
  },
  data() {
    return {
      teamOneStats: {},
      teamTwoStats: {},
      categories: [
        "____",
        "Points Per Game",
        "Yards Per Game",
        "Passing YPG",
        "Passing APG",
        "Passing TD",
        "INT",
        "Rushing YPG",
        "Rushing APG",
        "Rushing YPA",
        "Rushing TD",
        "FG",
        "Return TD",
      ]
    }
  },
  methods: {
    fetch() {
      Vue.axios.get(`https://site.web.api.espn.com/apis/site/v2/sports/football/college-football/teams/${this.teamOneId}/statistics?region=us&lang=en&contentorigin=espn`).then(res => {
        Vue.set(this, "teamOneStats", res.data);
      });
      Vue.axios.get(`https://site.web.api.espn.com/apis/site/v2/sports/football/college-football/teams/${this.teamTwoId}/statistics?region=us&lang=en&contentorigin=espn`).then(res => {
        Vue.set(this, "teamTwoStats", res.data);
      })
      // Vue.set(this, "teamOneStats", statsMock)
      // Vue.set(this, "teamTwoStats", statsMockTwo)
    },
    swap() {
      let swapHolder = this.teamOneStats;
      Vue.set(this, "teamOneStats", this.teamTwoStats);
      Vue.set(this, "teamTwoStats", swapHolder);
    },
    getStatistic(team, category, statistic, opponent) {
      if (opponent) {
        return team.results.opponent
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .displayValue;
      } else {
        return team.results.stats.categories
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .displayValue;
      }
    },
    getStatisticPerGameFromJson(team, category, statistic, opponent) {
      if (opponent) {
        return team.results.opponent
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .perGameDisplayValue;
      } else {
        return team.results.stats.categories
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .perGameDisplayValue;
      }
    },
    getStatisticPerGameFromJsonAsNumber(team, category, statistic, opponent) {
      if (opponent) {
        return team.results.opponent
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .perGameValue;
      } else {
        return team.results.stats.categories
            .find(({name}) => name === category)
            .stats.find(({name}) => name === statistic)
            .perGameValue;
      }
    },
    // only for defensive team because opponent properties don't have perGame values like regular statistics
    calculateStatisticPerGame(team, category, statistic) {
      let gameCount = team.results.stats.categories
          .find(({name}) => name === 'rushing')
          .stats.find(({name}) => name === 'teamGamesPlayed')
          .value;
      let aggregateStatisticValue = team.results.opponent
          .find(({name}) => name === category)
          .stats.find(({name}) => name === statistic)
          .value;
      return (aggregateStatisticValue / gameCount).toFixed(0);
    },
    goToEspn() {
      window.open("https://www.espn.com/college-football/game/_/gameId/"+ this.gameId, "_blank");
    },
  },
  computed: {},
  beforeMount() {
    this.fetch();
  }
}
</script>

<style scoped>
.information-label {
  text-align: right;
}
.information-detail {
  text-align: left;
}
.label {
  font-weight: bold;
  text-align: left;
}
.standard-button {
  color: #2c3e50;
  max-width: 250px;
  font-weight: bold;
  border-color: #2c3e50;
  background-color: white;
}
.standard-button:hover {
  color: white;
  font-weight: bold;
  border-color:  white;
  background-color:  #2c3e50;
}
.team-logo {
  width:50px;
  height:50px;
}
</style>
<template>
  <BContainer id="game-detail">
    <BRow>
      <BCol class="label" cols="6">
        <BContainer class="team-logo"></BContainer>
        <BRow :key="label" v-for="(statistics, label) in teamOne.statistics">
          {{statistics.label}}
        </BRow>
      </BCol>
      <BCol cols="3">
        <BImg @click.stop="this.goToEspn()" class="team-logo" v-bind:src=teamOne.team.logo></BImg>
        <BRow class="display-value" :key="label" v-for="(statistics, label) in teamOne.statistics">
          <BCol>
            {{statistics.displayValue}}
          </BCol>
        </BRow>
      </BCol>
      <BCol cols="3">
        <BImg class="team-logo" @click.stop="goToEspn" v-bind:src=teamTwo.team.logo></BImg>
        <BRow class="display-value" :key="label" v-for="(statistics, label) in teamTwo.statistics">
          <BCol>
            {{statistics.displayValue}}
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
export default {
  name: "GameDetail",
  props: {
    gameId: String,
    teamOne: Object,
    teamTwo: Object
  },
  methods: {
    goToEspn() {
      window.open("https://www.espn.com/college-football/game/_/gameId/"+ this.gameId, "_blank");
    },
  }
}
</script>

<style scoped>
.team-logo {
  width:50px;
  height:50px;
}
.display-value {
  text-align: center;
}
.label {
  font-weight: bold;
}
</style>
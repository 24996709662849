<template>
 <BContainer fluid="true">
   <BRow :class="teamWon" class="ml-0">
     <BCol class="team--name" cols="5">
       <BIconCaretRightFill v-if=possession /> {{rank}} <span v-if="this.$isMobile()">{{abbreviation}}</span><span v-else>{{teamName}}</span>{{spreadIfFavorite}}
     </BCol>
     <BCol class="align-content-end m-0 p-0" cols="7">
       <BRow class="justify-content-end m-0 p-0">
         <BCol class="mr-auto" cols="1" v-if="linescores">
           {{linescores[0] ? linescores[0].displayValue : ''}}
         </BCol>
         <BCol class="mr-auto" cols="1" v-if="linescores">
           {{linescores[1] ? linescores[1].displayValue : ''}}
         </BCol>
         <BCol class="mr-auto" cols="1" v-if="linescores">
           {{linescores[2] ? linescores[2].displayValue : ''}}
         </BCol>
         <BCol class="mr-auto" cols="1" v-if="linescores">
           {{linescores[3] ? linescores[3].displayValue : ''}}
         </BCol>
         <BCol class="mr-auto" cols="1" v-if="linescores && linescores.length > 4">
           {{linescores[4] ? linescores[4].displayValue : ''}}
         </BCol>
         <BCol class="mr-auto" cols="1" v-if="linescores && linescores.length > 5">
           {{linescores[5] ? linescores[5].displayValue : ''}}
         </BCol>
         <BCol class="mr-2" cols="1">
           <span :class="teamScored">{{score}}</span>
         </BCol>
       </BRow>
     </BCol>
   </BRow>
 </BContainer>
</template>
<script>
import Vue from 'vue';
import VueMobileDetection from "vue-mobile-detection";

Vue.use(VueMobileDetection);

export default {
  name: "Competitor",
  data() {
      return {
        homeAway: this.competitor.homeAway,
        teamId: this.competitor.teamId,
        score: this.competitor.score,
        teamName: this.competitor.team.location,
        abbreviation: this.competitor.team.abbreviation,
        logos: this.competitor.team.logos[0].href,
        possession: this.competitor.possession,
        record: this.competitor.record[0].displayValue,
        winner: this.competitor.winner,
        rank: this.competitor.rank,
        favorite_data: this.favorite,
        spread_data: this.spread,
        linescores: this.competitor.linescores
    }
  },
  props: {
    competitor: Object,
    scoringPlay: Object,
    favorite: Object,
    spread: Object,
  },
  methods: {
    updateCompetitor: function() {
      Vue.set(this, "homeAway", this.competitor.homeAway);
      Vue.set(this, "teamId", this.competitor.id);
      Vue.set(this, "score", this.competitor.score);
      Vue.set(this, "teamName", this.competitor.team.location);
      Vue.set(this, "logos", this.competitor.team.logos[0].href)
      Vue.set(this, "possession", this.competitor.possession)
      Vue.set(this, "record", this.competitor.record[0].displayValue)
      Vue.set(this, "winner", this.competitor.winner)
      Vue.set(this, "rank", this.competitor.rank)
      Vue.set(this, "favorite_data", this.favorite)
      Vue.set(this, "spread_data", this.spread)
      Vue.set(this, "linescores", this.competitor.linescores)
    }
  },
  watch: {
    competitor: "updateCompetitor",
    favorite: "updateCompetitor",
    spread: "updateCompetitor"
  },
  computed: {
    teamWon() {
      return this.winner ? 'team--won competitor' : 'competitor';
    },
    teamScored() {
      return this.scoringPlay.team === this.teamId ? 'team--scored team--score' : 'team--score';
    },
    spreadIfFavorite() {
      return this.competitor.id === this.favorite_data.favorite ? this.spread_data.spread_text : ''
    }
  }
}
</script>

<style scoped>

.competitor {
  font-size: 16px;
}
.team--won {
  background-color: #def8d6;
  font-weight: bold;
}
.team--scored {
  color: #91C788;
  background-color: #f6f6f6;
  font-weight: bold;
}

.team--score {
  text-align: left;
}

.team--name {
  text-align: left;
}

.team--spread {
  text-align: left;
  font-weight: normal;
}
@media (min-width: 576px) {

}

@media (min-width: 768px) {

}
</style>
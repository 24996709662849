import Vue from 'vue'
import VueRouter from 'vue-router'
import Scoreboard from '@/views/Scoreboard.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Scoreboard,
    },
    {
        path: '/:groupId',
        name: 'Scoreboard',
        component: Scoreboard,
        props: true
    }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <BContainer class="game m-0 p-0">
    <BRow>
      <BCol>
        {{clock}}
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <Competitor v-if="competitors[0]"
                    :competitor=this.competitors[0]
                    :scoring-play="scoringPlay"
                    :favorite="favorite"
                    :spread="spread"
        ></Competitor>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <Competitor v-if="competitors[1]"
                    :competitor=competitors[1]
                    :scoring-play="scoringPlay"
                    :favorite="favorite"
                    :spread="spread"
        ></Competitor>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        {{downDistance}}
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        {{lastPlayText}}
      </BCol>
    </BRow>
    <BRow class="justify-content-center">
      <BButtonGroup size="sm">
          <BButton variant="link" class="standard-button p-1 mt-1 mb-1" v-on:click="setDetailView('matchup')">Matchup</BButton>
          <BButton variant="link" class="standard-button p-1 mt-1 mb-1" v-if="scheduled !== 'Scheduled'" v-on:click="setDetailView('statistics')">Stats</BButton>
      </BButtonGroup>
    </BRow>
    <BRow>
      <BCol>
        <GameDetail v-if="this.detailView === 'statistics'"
            :team-one="statistics[0]"
            :team-two="statistics[1]"
            :game-id="gameId"
        ></GameDetail>
        <Matchup v-if="this.detailView === 'matchup'"
             :team-one-id="competitors[0].id"
             :team-two-id="competitors[1].id"
             :gameId="this.gameId"
        ></Matchup>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios'
import Competitor from "@/components/Competitor";
import GameDetail from "@/components/GameDetail";
import Matchup from "@/components/Matchup";

// import {gameInProgressMock} from "@/mocks/gameInProgress";
// import {gameFinishedMock} from "@/mocks/gameFinishedMock";


const COLLEGE_URL = "https://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=";
const NO_TEAM = "-1";

Vue.use(VueAxios, axios);
export default {
  name: "Game",
  components: {
    Competitor,
    GameDetail,
    Matchup
  },
  props: {
    index: Number,
    gameId: String,
    gameType: String,
    favorite: Object,
    spread: Object,
  },
  data() {
      return {
        data: {},
        scoringPlay: { team: NO_TEAM },
        lastPlay: {},
        competitors: [],
        gameIsFinished: false,
        clock: "",
        downDistance: "",
        lastPlayText: "",
        url: "",
        interval: 15 * 1000,
        statistics: [],
        scheduled: "",
        broadcast: {},
        venue: "",
        detailView: "matchup"
      }
  },
  watch: {
    data: "render"
  },
  mounted: function() {
    Vue.set(this, "url", COLLEGE_URL);
    this.callEspnUpdateData(this.url);
    window.setInterval(() => {
      if (!this.gameIsFinished) {
        this.callEspnUpdateData(this.url)
      }
    }, this.interval)
  },
  methods: {
    callEspnUpdateData(url) {
      Vue.axios.get(url + this.gameId).then((res) => {
        // Vue.set(this, "data", gameFinishedMock);
        Vue.set(this, "data", res.data)
      })
    },
    render() {
      this.handleDriveData();
      this.handleGameInfoData();
    },
    handleDriveData() {
      if (typeof this.data.drives != "undefined" && typeof this.data.drives.current != "undefined") {
        Vue.set(this, "lastPlay", this.data.drives.current.plays[this.data.drives.current.plays.length - 1]);
        Vue.set(this, "lastPlayText", this.lastPlay.text);
        Vue.set(this, "downDistance", this.lastPlay.end.downDistanceText);

        let wasScoringPlay = this.lastPlay.scoringPlay;
        if (wasScoringPlay) {
          Vue.set(this, "scoringPlay", { team: this.lastPlay.end.team.id });
        } else {
          Vue.set(this, "scoringPlay", { team: NO_TEAM });
        }
      }
    },
    setDetailView(view) {
      if (this.detailView === view) {
        this.detailView = '';
      } else {
        this.detailView = view;
      }
    },
    handleGameInfoData() {
      Vue.set(this, "competitors", this.data.header.competitions[0].competitors);
      Vue.set(this, "statistics", this.data.boxscore.teams);
      Vue.set(this, "clock", this.data.header.competitions[0].status.type.shortDetail);
      Vue.set(this, "scheduled", this.data.header.competitions[0].status.type.description);
      Vue.set(this, "broadcast", this.data.header.competitions[0].broadcasts[0])
      Vue.set(this, "venue", this.data.gameInfo.venue.fullName);
      Vue.set(this, "gameIsFinished", this.data.header.competitions[0].status.type.completed);
    }
  }
}
</script>

<style scoped>
.game {
  font-size: 14px;
}
.game:hover {
  cursor: pointer;
}
.standard-button {
  color: #2c3e50;
  font-weight: bold;
  border: none;
  background-color: white;
  height: 50px;
}
.standard-button:hover {
  color: white;
  font-weight: bold;
  background-color:  #2c3e50;
}
</style>